import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { BehaviorSubject, fromEvent, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() dataSource: MatTableDataSource<any>;
  @Input() loaded: boolean;
  @Input() routePath: string;
  @Input() searchTerm = '';
  @Input() section: string;

  @Output() sortColumnChanged = new EventEmitter<any>();

  @ViewChild('table', { static: false, read: ElementRef }) table: ElementRef;

  displayedColumns: string[];
  hoveredRow = null;
  rowTooltipLeftPosition = 0;
  showingAllCaughtUp = false;

  headerLeft$: BehaviorSubject<number> = new BehaviorSubject(0);
  isFixed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private element: ElementRef, private readonly utilService: UtilService) {}

  ngOnInit(): void {
    const content = document.getElementsByClassName('mat-drawer-content')[0];
    const quoteListTableElement = document.getElementsByTagName('table')[0];
    this.utilService.addResizeEventListener(quoteListTableElement, () => {
      this.positionRowTooltip();
    });

    // account for when swapping quote/order filtering
    fromEvent<Event>(content, 'scroll')
      .pipe(
        map(() => content.scrollTop),
        map((scrollTop: number) => {
          const threshold = 150; // TODO: Get this in a more meaningful manner
          return scrollTop >= threshold;
        }),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$)
      )
      .subscribe(this.isFixed$);

    this.isFixed$
      .pipe(
        filter((fixedHeader: boolean) => fixedHeader === true),
        switchMap(() => fromEvent<Event>(content, 'scroll')),
        map((event: Event) => content.scrollLeft * -1),
        distinctUntilChanged(),
        takeUntil(this.onDestroy$)
      )
      .subscribe(this.headerLeft$);

    // give <thead> tag correct width
    this.isFixed$
      .pipe(
        filter((fixedHeader: boolean) => fixedHeader === true),
        tap(this.styleTableHeader.bind(this)), // updates thead upon header becoming fixed
        switchMap(() => fromEvent<Event>(window, 'resize')),
        map(() => window.document.body.getBoundingClientRect().width),
        distinctUntilChanged(), // only call func when width changes
        takeUntil(this.onDestroy$)
      )
      .subscribe(this.styleTableHeader.bind(this));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showingAllCaughtUp =
      !this.searchTerm && (this.routePath === 'new' || (this.section === 'quotes' && this.routePath === 'saved'));

    if (this.dataSource?.data.length) {
      if (changes.dataSource?.currentValue) {
        this.selectColumnsToDisplay();
      }
    } else {
      this.displayedColumns = [];
    }
  }

  ngAfterViewInit(): void {
    /*
      when swapping between filtering the scroll remains the same
      so needs to check whether the table header should be fixed
      after quotes/orders are displayed
    */
    const content = document.getElementsByClassName('mat-drawer-content')[0];
    const scrollThreshold = this.table.nativeElement.offsetTop - 200;
    const scrollTop = content.scrollTop;
    const isFixed =
      scrollTop >= scrollThreshold && scrollTop <= scrollThreshold + this.table.nativeElement.clientHeight;
    this.isFixed$.next(isFixed);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  onSortColumChange(sortColumn: Sort): void {
    if (sortColumn.direction) {
      this.sortColumnChanged.emit({
        sortBy: sortColumn.active,
        sortDirection: sortColumn.direction
      });
    }
  }

  mouseLeaveRow(): void {
    this.hoveredRow = null;
  }

  mouseOverRow(row: string): void {
    this.hoveredRow = row;
  }

  tooltipDate(date: string) {
    return dayjs(date).local().format('ddd DD MMM YYYY HH:mm:ss');
  }

  private positionRowTooltip(): void {
    // Set position of quote line tooltip
    const rowTooltipWidth = document.getElementsByClassName('product-list__line-tooltip')[0]?.clientWidth || 30;
    this.rowTooltipLeftPosition = this.table.nativeElement.clientWidth / 2 - rowTooltipWidth / 2;
  }

  private selectColumnsToDisplay(): void {
    const columns = [];
    const add = (columnName: string) => columns.push(columnName);

    add('scheme');
    if (this.section === 'quotes') {
      if (this.routePath === 'submitted') {
        add('quoteNumber');
      }
    } else {
      add('orderRef');
    }
    add('status');
    add('orderDate');
    add('customerDetails');
    add('make');

    if (this.routePath === 'submitted') {
      add('quotedBy');
    }
    add('partCount');
    add('net');

    if (this.section === 'quotes') {
      add('details');
    } else {
      add('link');
    }

    this.displayedColumns = columns;
  }

  private styleTableHeader(): void {
    /*
      ensures child <thead> tag of table element's width matches that of the table
      which can't happen naturally due to <thead> being fixed.
    */

    if (this.table) {
      const thead = this.table.nativeElement.querySelector('thead');
      if (thead) {
        thead.style.width = this.table.nativeElement.clientWidth + 'px';
        const rect = this.element.nativeElement.getBoundingClientRect();
        thead.style.paddingLeft = rect.left + 'px';
        thead.style.paddingRight = rect.right + 'px';
      }
    }
  }
}
