import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { setKeyCloakInstance, setUserProfile } from './core/store/core.actions';
import { AppState } from './quotes/store/reducers';
import { EnvironmentConfigurationService } from './shared/services/environment-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userProfile: KeycloakProfile;
  private centreCodes: string[];
  private centreNames: string[];
  private tenantName = '';
  private userRoles: string[];

  constructor(
    private configurationService: EnvironmentConfigurationService,
    private readonly keycloakService: KeycloakService,
    private store: Store<AppState>
  ) {}

  /**
   * Clears user profile, centre codes and names
   */
  clearUserProfile(): void {
    this.centreNames = [];
    this.tenantName = '';
    this.userProfile = null;
  }

  /**
   * Returns current user full name.
   */
  getCurrentUser(): string {
    return `${this.userProfile?.firstName ? this.userProfile?.firstName + ' ' + this.userProfile?.lastName : ''}`;
  }

  getUserProfile(): KeycloakProfile {
    return this.userProfile;
  }

  getCentreNames(): string[] {
    return this.centreNames;
  }

  isAdminUser(): boolean {
    if (this.userRoles.indexOf('site_admin') > -1 || this.userRoles.indexOf('group_admin') > -1) {
      return true;
    } else {
      return false;
    }
  }

  // using part discount lookup / displaying part lookup badges
  usingDiscountLookup(): boolean {
    const tenantSubscribers = ['FORD', 'NISSAN'];
    const centerCodeSubscribers = ['NISSAN999']; //? Potential stop-gap while tenant does not include "NISSAN"
    return (
      tenantSubscribers.includes(this.tenantName) || this.centreCodes.some((c) => centerCodeSubscribers.includes(c))
    );
  }

  // subscribed to extra features i.e.: TLA, APP
  isSubscribed(): boolean {
    const subscribers = ['FORD']; // TODO: Maybe this should be pulled from somewhere
    return this.tenantName && subscribers.includes(this.tenantName);
  }

  isSiteAdminUser(): boolean {
    if (this.userRoles.indexOf('site_admin') > -1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Loads user profile, centre codes and names
   */
  async loadUserProfile(): Promise<any> {
    const keycloakInstance = this.keycloakService.getKeycloakInstance();
    this.userProfile = await keycloakInstance.loadUserProfile();
    this.store.dispatch(setUserProfile({ userProfile: this.userProfile }));
    this.store.dispatch(setKeyCloakInstance({ keyCloakInstance: keycloakInstance?.tokenParsed }));
    const parsedToken = keycloakInstance?.tokenParsed;
    if (parsedToken) {
      const mapper = parsedToken['autoflow-mapper'];
      const tenant = mapper && mapper[`${this.configurationService.getConfig('keycloakClientId')}`];
      this.centreCodes = parsedToken.centre_codes;
      this.centreNames = parsedToken.centre_names;
      this.tenantName = tenant?.tenant_name ?? '';
      this.userRoles = parsedToken.realm_access.roles;
    }
  }
}
