import { Injectable } from '@angular/core';
import { ERoundingScheme } from '../models/parts-list.model';

@Injectable({
  providedIn: 'root'
})
export class RoundingService {
  public roundingScheme: ERoundingScheme = ERoundingScheme.AUTO;

  /*
    ROUND VALUE USING ROUNDING SCHEME (TO TWO DECIMAL PLACES)
    ? DYNAMIC ROUNDING (DECIMAL PLACE AS A PARAM ETC.)
  */
  roundValue(value: number, scheme = ERoundingScheme.AUTO): string {
    // FIRST CHECK IF GREATER THAN TWO DECIMAL PLACES
    if (value.toString().indexOf('.') === -1 || value.toString().length - value.toString().indexOf('.') <= 3) {
      return value.toString(); // ALREADY 2 DECIMALS OR LESS
    }

    switch (scheme) {
      case ERoundingScheme.ROUND_UP:
        return (Math.ceil(value * 100) / 100).toString();
      case ERoundingScheme.ROUND_DOWN:
        return (Math.floor(value * 100) / 100).toString();
      case ERoundingScheme.AUTO:
      default:
        return (Math.round(value * 100) / 100).toString();
    }
  }
}
