import { Injectable } from '@angular/core';
import { filter, fromEvent, Observable } from 'rxjs';
import { EnvironmentConfig } from './environment-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  constructor() {}

  addResizeEventListener(domElement: Element, callback: () => void): void {
    const resizeObserver = new ResizeObserver(() => callback());
    resizeObserver.observe(domElement);
  }

  decodeBase64String(envString: string): EnvironmentConfig {
    return JSON.parse(atob(envString));
  }

  extractUser(text: string = '') {
    // Assumes text is in the form 'user-id (user-name)'
    const separatorIndex = text.indexOf(' ');
    return { id: text.slice(0, separatorIndex), name: text.slice(separatorIndex + 2, -1) };
  }

  getClosestParent(target: HTMLElement, className = 'dialog', depth = 0): HTMLElement {
    const maxDepth = 20;
    if (
      !target ||
      typeof target?.className?.includes !== 'function' ||
      depth > maxDepth
    ) {
      // Give up searching
      return null;
    } else if (className === 'dialog' ? target.classList.contains(className) : target.className.includes(className)) {
      return target;
    }
    return this.getClosestParent(target.parentElement, className, depth + 1);
  }

  /**
   * @param originalObject
   * @returns A deep copy of originalObject
   */
  getCopyOf(originalObject: any): any {
    return JSON.parse(JSON.stringify(originalObject));
  }

  getObservableKeyboardEvent(event: string, key: string): Observable<KeyboardEvent> {
    return (
      fromEvent<KeyboardEvent>(window, event)
        .pipe(
          filter((e: KeyboardEvent) => e.key === key)
        )
    );
  }

  getObservableMouseEvent(event: string): Observable<MouseEvent> {
    return fromEvent<MouseEvent>(window, event);
  }

  getUserNameInitials(userName: string): string {
    const lastSpace = userName.lastIndexOf(' ');
    return (userName.charAt(0) + userName.slice(lastSpace + 1, lastSpace + 2)).toUpperCase();
  }

  /**
   * @param simpleObject An array or object
   * @returns true if simpleObject is empty
   */
  isEmpty(simpleObject: any): any {
    if (Array.isArray(simpleObject)) {
      return !simpleObject.length;
    }
    return !Object.keys(simpleObject).length;
  }

  async waitUntilResolved(fn: any, delay = 500, maxTries = 50): Promise<boolean> {
    return new Promise((resolve) => {
      let tries = 0;
      const timer = setInterval(() => {
        if (fn() || (tries += 1) > maxTries) {
          clearInterval(timer);
          resolve(true);
        }
      }, delay);
    });
  }
}
